import React, {useState} from "react";
import "../css/AdminLogin.css";
import axios from "axios";
import {useNavigate} from "react-router-dom";

function AdminLogin(){

    const navigate = useNavigate();

    //const base_url = "http://localhost/leisureartmarinephpbackend";
    const base_url = "https://artmarineuae.com/leisureartmarinephpbackend";
    const initialError = { username:"", password:""};
    const [errorMsg, setErrorMsg] = useState(initialError);
    const initialInput = { username: "", password: ""};
    const [inputUserLoginState, setInputUserLoginState] = useState(initialInput);


    const handleInput = (event)=>{
        setErrorMsg(initialError);
        setInputUserLoginState({...inputUserLoginState, [event.target.name]: event.target.value});
    }

    const callLoginAPI = ()=>{

        if( inputUserLoginState.username.length > 0 ){
        
                    if( inputUserLoginState.password.length > 0 ){

                        console.log("inputUserLoginState is ", inputUserLoginState);

                    axios.post(base_url+'/login-admin.php', inputUserLoginState).
                    then((res)=>{
                        console.log("login result is ",res );
                        if(res.data.status == "login_success"){
                        
                            localStorage.setItem(
                                "leisure_art_marine_admin_login_status",
                                JSON.stringify({status: "login_success" })
                              );
                             navigate(`/admin_dashboard`);

                        }else{
                            console.log("login failed reason ", res.data);
                            setErrorMsg( prev => ({...prev, password: res.data.message}) )
                        }
                    }).catch(err => console.log("error is ", err));
                    
                    }else{
                        setErrorMsg( prev => ({...prev, password: "Please enter password"}) )
                    }

        }else{
            setErrorMsg(prev => ({...prev, username: "Please enter username"}) )
        }
    }

    return(
        <div className="AdminLogin-root-container">


              <div className="AdminLogin-form">
                  <span>
                  <label>Username</label>
                  </span>
                  <input type="text" name="username" className="mt-2" value={inputUserLoginState.username} onChange={(event)=>{handleInput(event)}} />
                  <p className="AdminLoginform-input-error">{errorMsg.username}</p>
                  <label className="mt-3">Password</label>
                  <input type="password" name="password" className="mt-2"  value={inputUserLoginState.password} onChange={(event)=>{handleInput(event)}} />
                  <p className="AdminLoginform-input-error">{errorMsg.password}</p>
                  <button className="mt-4" onClick={callLoginAPI}>Log in</button>
              </div>

        
        </div>
    )

}

export default AdminLogin;