import React from "react";
import home_page_hero_bg from "../img/home_page_hero_bg.png";
import "../css/Home.css";
import ic_quality_yacht_icon from "../img/ic_quality_yacht_icon.png";
import ic_statistics_container_bg from "../img/ic_statistics_container_bg.png";
import home_page_video from "../videos/home_page_video.mp4";
import Footer from "./Footer";

function Home(){
    return(
        <div className="Home-root-container">

            <div className="Home-top-container">
                <div className="Home-hero-image-container">
                    <img src={home_page_hero_bg}/>
                </div>
                <div className="Home-hero-text-container">
                    <div className="container">
                        <p>Welcome to Leisure Art Marine.<br/> The specialists in Yacht repair.</p>
                        <a href="/services">Explore our services</a>
                    </div>
                </div>
            </div>

            <div className="Home-our-qualities-panel">
                <p>At Leisure Art Marine we provide best service, best price, <br /> best materials.</p>
                <img src={ic_quality_yacht_icon} />
            </div>
            
            <div className="Home-statistics-container">  
                <div className="Home-statistics-bg-image-container">
                    <img src={ic_statistics_container_bg}/>
                </div>

                <div className="Home-statistics-black-overlay">

                </div>
                <div className="Home-statistics-text-container">
                    <div className="Home-statistics-text-value-container container">
                        <h4>Our journey so far</h4>
                        <div className="Home-statistics-glass-card-container">
                            <div className="Home-statistics-glass-container-first-child">
                                <label className="Home-statistics-years-of-experience-value">10 +</label>
                                <label>Years of experience in the industry</label>
                            </div>

                            <div className="Home-statistics-glass-container-second-child">
                                <div className="Home-statistics-glass-container-second-child-clients">
                                    <label className="Home-statistics-number-of-clients-value">57</label>
                                    <label>Clients</label>
                                </div>
                                <hr />
                                <div className="Home-statistics-glass-container-second-child-projects">
                                    <label className="Home-statistics-years-of-experience-value">124</label>
                                    <label>Projects</label>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="Home-statistics-container-footer-note-container">
                    <p className="container">
                        Our experienced employees completes every yacht <br/> works with utmost precision &amp; passion.
                    </p>
                </div>
            </div>

            <div className="Home-bottom-container">

                <p>quality . precision . timing</p>

                <div className="Home-bottom-container-video-player-container">

                    <div>
                    <video width="100%" controls >
                        <source src={home_page_video} type="video/mp4"/>
                    </video>
                    </div>

                </div>

            </div>
            <Footer/>

        </div>
    )
}

export default Home;