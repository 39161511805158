import React from "react";
import "../css/Footer.css";

function Footer(){

    return (
        <div className="Footer-root-container">

            <div className="Footer-text-container container">
                <div className="Footer-about-section">
                    <h5>About Leisure Art Marine</h5>
                    <p>At Leisure Art Marine we provide best service, best price, best materials.</p>
                </div>
                <div className="Footer-quick-links">
                    <h5>Quick Links</h5>
                    <ul>
                        <li><a href="/"> Home </a></li>
                        <li><a href="/aboutus"> About Us</a></li>
                        <li><a href="/services">Service</a></li>
                        <li><a href="/gallery">Gallery</a></li>
                        <li><a href="/contact">Contact</a></li> 
                    </ul>
                </div>
              
                <div className="Footer-location-section">
                <h5>Contact</h5>
                    <p>LEISURE ART MARINE SERVICES LLC <br/> 2nd Gate, Shed 70B <br/> Al Jadaf Dry Dock, Dubai, UAE <br/> P.O BOX : 118526 <br/> Tel - +971 4 3369993<br/>
                        Email - info@artmarineuae.com</p>
                </div>
            </div>

        </div>
    )


}

export default Footer;