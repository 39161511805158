import React ,{useState, useEffect} from "react";
import "../css/Contact.css";
import ic_about_us_bg from "../img/ic_about_us_bg.png";
import ic_page_header_name_bg from "../img/ic_page_header_name_bg.png";
import Footer from "./Footer";
import axios from "axios";import ic_check_mark from "../img/ic_check_mark.png"; 

function Contact(){

    
    //const base_url = "http://localhost/leisureartmarinephpbackend";
    const base_url = "https://artmarineuae.com/leisureartmarinephpbackend";

    let alreadYAPISent = false;
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const initialError = {
        customer_name: "",
        customer_mobile: "",
        customer_email:"",

    }

    const[initialErrorState, setInitialErrorState] = useState(initialError)

    const initialArrayEnquiryDetails = {
        customer_name: "",
        customer_mobile: "",
        customer_email: "",
        customer_message: ""
    }
    const [enquiryDetails, setEnquiryDetails] = useState(initialArrayEnquiryDetails)

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

      const enterEnquiryDetails = (e)=>{
        console.log("typed content from input ", e.target.name , e.target.value);

        if(e.target.value.length > 0){
            setInitialErrorState(prev => ({...initialErrorState, [e.target.name]:""}))
        }else{
            switch(e.target.name){
                case "customer_name": setInitialErrorState(prev => ({...prev, customer_name: "please enter name"}));
                break;
                case "customer_mobile" : setInitialErrorState(prev => ({...prev, customer_mobile: "please enter mobile number"}));
                break;
                case "customer_email" : setInitialErrorState(prev=> ({...prev, customer_email: ""}));
                break;
            }
        }

        setEnquiryDetails( {...enquiryDetails, [e.target.name]: e.target.value} )
    }

    const sendEnquiryDetails = () => {
        console.log("current enquiry details are ", enquiryDetails);

        if(enquiryDetails.customer_name.length >  0 && enquiryDetails.customer_mobile.length > 0 ){
            
            // first check if there is any text in email field, if there is atleast one character 
            // show email error paragraph
            if(enquiryDetails.customer_email.length > 0){

                console.log("email length greater than zero")

                if(validateEmail(enquiryDetails.customer_email)){
                    
                    console.log("email length is valid");
                    setInitialErrorState(prev=> ({...prev, customer_email: ""}));
                    allFieldsAreSetNowSendToServer();
                }else{
                    
                    console.log("email length is invalid");
                    setInitialErrorState(prev=> ({...prev, customer_email: "please enter a valid email"}));
                }

            }else{
                console.log("inside else email less than zero" );
                setInitialErrorState(prev=> ({...prev, customer_email: ""}));  
               
            }
            
        }
        else{
            if(enquiryDetails.customer_name.length == 0){
                setInitialErrorState(prev=> ({...prev, customer_name: "Please enter name"}));
            }
            if(enquiryDetails.customer_mobile.length == 0){
                setInitialErrorState(prev=> ({...prev, customer_mobile: "Please enter mobile number"}));
            }
        }        
    }


    const allFieldsAreSetNowSendToServer = () =>{

            console.log("inside allFieldsAreSetNowSendToServer ",  JSON.stringify(enquiryDetails));
        
            if(!alreadYAPISent){
            
                console.log("inside alreadYAPISent ");
           
                alreadYAPISent = true;
                axios({
                    method: 'post',
                    url: base_url + '/form-process.php',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    data:  JSON.stringify(enquiryDetails)
                }).
                then((res)=>{
                            console.log("successfully created enquiry ", res);
                            alreadYAPISent = false;

                            if(res.data.status === "entry_success"){
                                setShowSuccessPopup(prev => !prev);
                                setEnquiryDetails(initialArrayEnquiryDetails);

                                // setIsAllRequiredInputAreFilled(false);
                                setInitialErrorState(initialErrorState);          
                            }else{
                                console.log("entry failure");
                            }
            }).
            catch((err)=>{
                alreadYAPISent = false;
                console.log(" error while creating work ", err); 
            } );
            }else{
                console.log("sorry aPI already sent");
            }
    }
    

    return(
        <div className="Contact-root-container">
                <div className="Contact-top-container">
                    <div className="Contact-image-container">
                        <img src={ic_about_us_bg} />
                    </div>
                    <div className="Contact-statistics-black-overlay">
                    </div>
                <div className="Contact-text-container">

                    <div className="Contact-text-container-details ">
                        <div className="Contact-name-panel-container mt-4 container">
                            <img src={ic_page_header_name_bg}/>
                            <h3>Contact</h3>
                        </div>
                        <div className="Contact-address-container">
                            <div className="container">
                                <h4 className="Contact-address-container-head-label">Head Office</h4>

                                <div className="Contact-address-card">
                                    <div className="Contact-address-card-first-child">
                                        
                                        <p>LEISURE ART MARINE SERVICES LLC <br/> 2nd Gate, Shed 70B <br/> Al Jadaf Dry Dock, Dubai, UAE <br/> P.O BOX : 118526 <br/> Tel: +971 4 3369993</p>
                                    
                                    </div>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14438.62958900515!2d55.334098!3d25.2147738!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4e43652d6634c454!2sLEISURE%20ART%20MARINE%20SERVICES%20LLC!5e0!3m2!1sen!2sae!4v1656934083409!5m2!1sen!2sae"  style={{border:0}} width="600" height="450" allowFullScreen="" ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <div className="Contact-typing-box-fields-container">
                        <div className="container">
                            <h3>Contact Us</h3>
                            <p>Get Quotes, Ask questions, Send messages. We are happy to hear from you</p>
                            <div className="Contact-typing-box-fields-container-top-panel">
                                <span>
                                    <label className="Contact-typing-box-full-length-label">Name <div className="mandatory-input-symbol ms-1">*</div></label>
                                    <input type="text" name="customer_name" value={enquiryDetails.customer_name} onChange={event => enterEnquiryDetails(event)}/>
                                    <p className="Contact-form-error-message">{initialErrorState.customer_name}</p> 
                                </span>
                                
                                <span>
                                    <label  className="Contact-typing-box-full-length-label">Mobile Number <div className="mandatory-input-symbol ms-1">*</div></label>
                                    <input type="number" name="customer_mobile" value={enquiryDetails.customer_mobile} onChange={event => enterEnquiryDetails(event)}/> 
                                    <p className="Contact-form-error-message">{initialErrorState.customer_mobile}</p> 
                                </span>
                                
                                <span>
                                    <label>Email Id</label>
                                    <input type="email" name="customer_email" value={enquiryDetails.customer_email} onChange={event => enterEnquiryDetails(event)}/>
                                    <p className="Contact-form-error-message">{initialErrorState.customer_email}</p>
                                </span>
                            </div>
                            <div className="Contact-typing-box-fields-container-bottom-panel">
                                <span>
                                    <label>Message</label>
                                    <textarea rows="4" name="customer_message" value={enquiryDetails.customer_message} onChange={event => enterEnquiryDetails(event)} >
                                        
                                    </textarea>
                                </span>
                                
                            </div>
                            <div className="Contact-typing-box-fields-send-button-container">
                                <button onClick={sendEnquiryDetails}>Send </button>
                            </div>
                        </div>
                    </div>

                    <Footer/>
                </div>
               
            </div>

            {/* success popup starts */}
{showSuccessPopup  && (<div className="Contact-enquiry-entry-successPopup-outer">
                <div className="Contact-enquiry-entry-successPopup-inner">
                    <div className="Contact-image-and-thank-you">
                        <img src={ic_check_mark}/>
                        <span>Thank you.</span>
                    </div> 

                    <div><p>Enquiry submitted. Our team member will contact you shortly.</p></div>   
                    <button onClick={() => setShowSuccessPopup(prev => !prev)}>OK</button>          
                </div>
            </div>)}
            
{/* success popup starts */}
        </div>
    )
}

export default Contact;