import React, { useEffect, useState } from "react";
import "../css/AdminHome.css";
import axios from "axios";
import {useNavigate} from "react-router-dom";

function AdminHome(){

   // const base_url = "http://localhost/leisureartmarinephpbackend";
    const base_url = "https://artmarineuae.com/leisureartmarinephpbackend";
    const [showPopupState, setShowPopupState] = useState(false);
    const [currentPageNumberState, setCurrentPageNumberState] = useState(1);
    const [isAuthorisedAccess, setIsAuthorisedAccess] = useState(false)
    const navigate = useNavigate();
    const [enquiryListArrayState, setEnquiryListArrayState] = useState([]);

    let dummyObject = {currentPageNo : 1}

    const logoutPopupShowHide =()=>{
        setShowPopupState(prev => !prev);
    } 
    const logOutUser = ()=>{

        logoutPopupShowHide();

        // axios.post('http://localhost:3001/admin-logout', {withCredentials: true}).
        // then((res)=> {
        //     console.log("logout api response ", res );
        //     if(res.data.isLoggedOut){
        //         console.log("isLogged out true");
        //         navigate('/');
        //     }
        //  } ).
        // catch(err => console.log("logout catch error ", err));
    }

    const getListOfEnquiriesAPI = ()=>{

        axios.post(base_url + '/get-enquiry-list.php',dummyObject ).
        then(result =>{
            console.log(result.data);

            setEnquiryListArrayState(result.data.enquiry_list_array);
            
        }).catch((err)=>{
            console.log("catch error in listOfEnquiries :( :( :( " , err);
        })

    }


    // intercept response check for 401 response from backend 
    // then send refreshtoken request
    // then repeat previous request automatically, hence user won't be interfered

    // axios.interceptors.response.use((response)=> {
    //     return response;
    // }, 
    // async function (error){
    //     console.log("axios interceptor error {{}}}}}", error);

    //       if(error.response.status === 401){
              
    //         const uname = localStorage.getItem("leisure_admin_username");

    //         const originalRequest = error.config;

    //         console.log("axios interceptor /////???\\\\\\ ", error);
    //         // return axios.request(error.config);
    //         //return axios.request('http://localhost:3001/createNewAccessToken', {withCredentials: true});
    //         //if we send only url and withcredentials then backend refresh token is empty 
    //         // so we are sending an empty object between url and withcredentials

    //         return axios.post('http://localhost:3001/accessTokenTimedOut',{uname:uname},{withCredentials:true}).
    //         then(res => {
    //                 if(res.data.isTokenRefreshed){
    //                     console.log("accessTokenTimedOut returnded with yaaayy \\\\\\\\/////// ", res);
    //                     return axios.request(originalRequest) ;

    //                 }else{
    //                     console.log("accessTokenTimedOut returnded with -(*_*)- \\\\\\\\/////// ", res);
    //                 }   
    //             }
    //         ); 
            
    //       }else{
    //         console.log("axios interceptor inside else of error is 401 refresh token required", error); 
    //       }
    // })

    const CheckIfLoggedIn =() =>{

        if(JSON.parse(localStorage.getItem("leisure_art_marine_admin_login_status")))
        {
            let loginStatus = JSON.parse(localStorage.getItem("leisure_art_marine_admin_login_status"));
            if(loginStatus.status == "login_success"){
                setIsAuthorisedAccess(true);
            }else{
                setIsAuthorisedAccess(false);
            }
        }else{
            setIsAuthorisedAccess(false);
        }
       
    }

    useEffect(()=>{
        // callAuthorizationAPI();
        CheckIfLoggedIn();
    }, [])

    useEffect(()=>{

        if(isAuthorisedAccess){
            getListOfEnquiriesAPI();
        }

    }, [isAuthorisedAccess])

    const logoutFromWebsite = ()=>{
        logoutPopupShowHide();

        localStorage.setItem(
            "leisure_art_marine_admin_login_status",
            JSON.stringify({status: "logged_out" })
          );
        navigate('/');
    }
    
    return(
        <div className="AdminHome-root-container">

            {
                
            isAuthorisedAccess 
            
            ?

                <div>
                    <div className="AdminHome-main-content container">

                        
                        <div className="AdminHome-head-panel-heading-and-link">
                            <h5>Enquiry List</h5> 
                            <a href="/admin_dashboard/gallery">Go to Gallery</a>
                            <span>
                            <button className="AdminHome-logout-button" onClick={logOutUser}>Logout</button>
                            </span>
                        </div>
                        
                        <div className="AdminHome-enquiry-list-container">

                            <ul >

                                {enquiryListArrayState.length > 0 ? 
                                    enquiryListArrayState.map((item)=>{
                                        return(
                                        <li key={item.id} className="mb-3">
                                            <div className="AdminHome-card-container">
                                                <div className="AdminHome-enquiry-top-panel">

                                                    <div>
                                                        <label>Name</label>
                                                        <label>{item.name}</label>
                                                    </div>

                                                    <div>
                                                        <label>Mobile</label>
                                                        <label>{item.mobile_number}</label> 
                                                    </div>

                                                    <div>
                                                        <label>Email</label>
                                                        <label>{item.email}</label>
                                                    </div>
                                                </div>
                                                <div className="AdminHome-enquiry-bottom-panel mt-4">
                                                    <div>
                                                        <label>Message</label>
                                                        <label>{item.message}</label>
                                                    </div>

                                                </div>
                                            </div>
                                        </li>
                                        )
                                    })
                                    
                                    :
                                    null
                                }
                                
                            </ul>

                        </div>
                    </div>
                </div>

            :

            <div className="AdminGallery-unauthorized-access-container container">
                <p>Unauthorized Access</p>
            </div>

            }

            {showPopupState && 
                <div className="LogoutPopup-root-container">
                    
                    <div className="LogoutPopup-root-container-child card">
                        <h4>Log out</h4>
                        <div>
                            Are you sure?
                        </div>
                        <div className="LogoutPopup-child-button-container mt-4">
                            <button onClick={logoutPopupShowHide}>Cancel</button>
                            <button onClick={logoutFromWebsite} className="ms-4 bg-danger text-white">Log out</button>
                        </div>
                    </div>
                </div>
            }

        </div>
    )

}

export default AdminHome;