import React from "react";
import "../css/Header.css";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import ic_logo_leisure_art_marine from "../img/ic_logo_leisure_art_marine.png";
import ic_phone_circle from "../img/ic_phone_circle.png";
import ic_mail_circle from "../img/ic_mail_circle.png";

function Header(){
    return(
        <div className="Header-root-container">

    
            <Navbar collapseOnSelect expand="lg" className="Header-top-navbar container">
                <Container fluid className="header-top-bar">
                <Navbar.Brand href="/">
                    <img src={ic_logo_leisure_art_marine} className="header_company_logo" />
                    <span className="Header-company-name ms-4">Leisure Art Marine </span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav Navbar-right-contact-and-menu-container">
                    <div className="Header-phone-email-container">
                        <div>
                            <img src={ic_mail_circle} />
                            <label className="Header-email-value ms-2">info@artmarineuae.com</label>
                        </div>
                        <div className="ms-4">
                            <img src={ic_phone_circle}/>
                            <label className="Header-email-value ms-2">+971 4 3369993</label>
                        </div>
                    </div>
                    <Nav className="ms-auto Header-nav-container">
                        <Nav.Link eventKey="1" as={NavLink} to="/" exact="true" active={false}>
                            Home
                        </Nav.Link>
                        <Nav.Link eventKey="2" as={NavLink} to="/aboutus" active={false}>
                            About Us
                        </Nav.Link>
                        <Nav.Link eventKey="3" as={NavLink} to="/services" active={false}>
                            Services
                        </Nav.Link>
                        <Nav.Link eventKey="4" as={NavLink} to="/gallery" active={false}>
                            Gallery
                        </Nav.Link>
                        <Nav.Link eventKey="5" as={NavLink} to="/contact" className="pe-0" active={false}>
                            Contact 
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                </Container>
            </Navbar>
      </div>
    )
}

export default Header;