import React from "react";

function checkIfLoggedIn(props){

    let setIsAuthorisedAccess = props;

        if(JSON.parse(localStorage.getItem("leisure_art_marine_admin_login_status")))
        {
            let loginStatus = JSON.parse(localStorage.getItem("leisure_art_marine_admin_login_status"));
            if(loginStatus.status == "login_success"){
                //setIsAuthorisedAccess(true);
                return(true)
            }else{
                //setIsAuthorisedAccess(false);
                return (false)
            }
        }else{
            //setIsAuthorisedAccess(false);
            return(false);
        }
       
    

}
export {checkIfLoggedIn};