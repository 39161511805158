import React from "react";
import ic_about_us_bg from "../img/ic_about_us_bg.png";
import ic_page_header_name_bg from "../img/ic_page_header_name_bg.png";
import "../css/Services.css";
import ic_services_main_blob from "../img/ic_services_main_blob.svg";
import ic_services_front_blob from "../img/ic_services_front_blob.svg";
import ic_services_back_blob from "../img/ic_services_back_blob.svg";
import ic_services_video from "../videos/ic_services_video.mp4";
import ic_ocean_led_services from "../img/ic_ocean_led_services.png";
import ic_rakicevic_services from "../img/ic_rakicevic_services.png";
import Footer from "./Footer";

function Services(){
    return(
        <div className="Services-root-container">

            <div className="Services-top-container">
                <div className="Services-image-container">
                    <img src={ic_about_us_bg} />
                </div>
                <div className="Services-statistics-black-overlay">
                </div>
                <div className="Services-text-container">
                    <div className="Services-text-container-details ">
                        <div className="Services-name-panel-container mt-4 container">
                            <img src={ic_page_header_name_bg}/>
                            <h3>Services</h3>
                        </div>
                        <div className="Services-list-container">

                            <div className="Services-blob-container">
                                <img src={ic_services_back_blob}/>
                                <img src={ic_services_main_blob}/>
                                <img src={ic_services_front_blob}/>
                            </div>
                            <div className="Services-list-container-inner-content container">
                                <ul>
                                    <li>Marine Products</li>
                                    <li>Yacht Services</li>
                                    <li>Yacht Brokerage</li>
                                    <li>Registration Services</li>
                                    <li>Painting and Detailing</li>
                                    <li>Fiber glass and carbon fiber works</li>
                                    <li>Interior and Exterior &amp; Upholstery</li>
                                    <li>Mechanical</li>
                                    <li>A/C and Hydraulic Electrical and Electronics Services</li>
                                    <li>Fabrication and Carpentry</li>
                                </ul>
                            </div>

                        </div>

                        <div className="Services-led-container">
                            <div className="Services-led-container-inner-content container">
                                <label>Vibrant lighting options for <br/> electrifying experience.</label>
                            </div>
                            <hr className="Services-led-tagline-underline" />
                            
                            <div className="Services-bottom-container-video-player-container">
                                <div>
                                <video width="100%" height="500" controls >
                                    <source src={ic_services_video} type="video/mp4"/>
                                </video>
                                </div>
                            </div>
                            <h4>OFFICIAL DEALERS OF</h4>
                            <hr className="Services-led-tagline-underline" />
                            <div className="Services-brand-name-container">
                                <img src={ic_ocean_led_services}/>
                                <span>
                                    <img src={ic_rakicevic_services} />
                                </span>
                            </div>
                        </div>

                    </div> 

                    <Footer/>
                </div>
            </div>
        </div>
    )
}

export default Services;