import React, {useState, useRef, useEffect} from "react";
import "../css/AdminGallery.css";
import ic_dropdown_work_list from "../img/ic_dropdown_work_list.png"; 
import axios from "axios";
import ic_check_mark from "../img/ic_check_mark.png"; 
import {checkIfLoggedIn} from "./IsAdminLoggedIn";

function AdminGallery(){
    
    const [isAuthorisedAccess, setIsAuthorisedAccess] = useState(false)
    const [loadingItemsTextState, setLoadingItemsTextState] = useState("");
    //const base_url = "http://localhost/leisureartmarinephpbackend";
    const base_url = "https://artmarineuae.com/leisureartmarinephpbackend";
    const [arrayOfGalleryImages, setArrayOfGalleryImages] = useState([]);
    const [file, setFile] = useState();
    const [errorMsg, setErrorMsg] = useState("");
    const [isAllRequiredInputAreFilled, setIsAllRequiredInputAreFilled] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const initialInput = { category_type:"" , file: file};
    const [inputCreateTicketState, setInputCreateTicketState] = useState(initialInput);
    const firstFileRef = useRef(null);
    const [statusDropDownVisibility, setStatusDropDownVisibility] = useState(false);
    const [categoryArrayState, setCategoryArrayState] = useState(["Services","Marine Products Division"]);
    const [currentIndexOfCategoryListState, setCurrentIndexOfCategoryListState] = useState(0); 

    const handleStatusFilterClick =(index)=>{
        setCurrentIndexOfCategoryListState(index);
        setStatusDropDownVisibility(prev => !prev);
    }

    const onFileSelected = (e)=>{
        setErrorMsg("");
        setFile(e.target.files);
    }

    const validateInputFields = ()=>{

        if(file == null ){
            return false;
        }else{
            return true;
        }
    }

    const sendButtonClicked = (event)=>{
            event.preventDefault();
            setErrorMsg("");
            console.log("sendButtonClicked");

            let resultAfterValidation =  validateInputFields();

            if(resultAfterValidation){
            
                const galleryFormData = new FormData();
                galleryFormData.append("category_type",categoryArrayState[currentIndexOfCategoryListState]);
                galleryFormData.append("gallery_images_array",  firstFileRef.current.files[0]);

                // List key/value pairs
                    for(let [name, value] of galleryFormData) {

                        console.log("galleryFormData is ", name, value);
                    }
    
                console.log("galleryFormData is ", galleryFormData);

                try{
                
                    axios({
                        method: "post",
                        url : base_url + "/upload-image.php",
                        data : galleryFormData,
                        headers : {"Content-Type": "multipart/form-data"}
                    }).
                        then((res)=>{
                            console.log("upload result is ", res);
                            if(res.data.upload_status == "success"){
                                console.log("image uploaded successfuly");
                                // clear input fields and files
                                setInputCreateTicketState(initialInput);
                                firstFileRef.current.value = null; 
                                //secondFileRef.current.value = null;
            
                                getPreviousImagesFromServer();

                                //show popup
                                setShowSuccessPopup(prev => !prev);
            
                                //set isAllRequiredInputAreFilled to false 
                                // else if we type and send ticket immediately after the first ticket
                                // since IsAllRequiredInputAreFilled is already true nothing happens 
                                //ie useEffect code wont work,
                                // so we set to false
                                setIsAllRequiredInputAreFilled(false);
                                
                            }else{
                                setErrorMsg(res.data.errorMessage);
                                console.log("login failed reason ", res.data);
                            }
                        }).catch(err => {
                            setErrorMsg("Create Request Failed... Please contact admin");
                            console.log("err on front end ", err);
                        });
            
                }catch(err){
                        console.log("error got from try catch on create ticket post", err)
                };

          
            }else{
                console.log("please upload an image");
            }
    }


    const getPreviousImagesFromServer = ()=>{

        setLoadingItemsTextState("Loading Images")
        axios.get(base_url+'/get-previous-images.php').
        then((res)=>{

            console.log("front end success ", res);

            if(res.data.status == "list_success"){
                

            // select first aitem automatically on every new ticket list ie every new api call
            // so set currentSelectedItem to zero
            // when i put this statement below setArrayOfTickets(res.data.resultData); it showed error
            // when selecting 76th, 75, 74 and clicking next
            // because then currentselect index is 2, 3, 4 ie greater than 1 by the time new array came with size 2
            // so state also changed , then array gets looped still if index is greater than 1 index is not found
            // then array index not found exception occurs
           
            setArrayOfGalleryImages(res.data.gallery_list_array);

            if(res.data.gallery_list_array.length == 0){
                setLoadingItemsTextState("No images found");
            }else{
                setLoadingItemsTextState("");
            }

            
        }else{
            console.log("couldn't get previous images");
        }
           
        }).catch(err => console.log("fron end error ", err));
    }

    useEffect(()=>{
        setIsAuthorisedAccess(checkIfLoggedIn());
    }, [])

    useEffect(()=>{

        if(isAuthorisedAccess){
            getPreviousImagesFromServer();
        }

    }, [isAuthorisedAccess])

     // intercept response check for 401 response from backend 
    // then send refreshtoken request
    // then repeat previous request automatically, hence user won't be interfered
    // axios.interceptors.response.use((response)=> {
    //     return response;
    // }, 
    // async function (error){
    //     console.log("axios interceptor error {{}}}}}", error);

    //       if(error.response.status === 401){
              
    //         const uname = localStorage.getItem("leisure_admin_username");

    //         const originalRequest = error.config;

    //         console.log("axios interceptor /////???\\\\\\ ", error);
    //         // return axios.request(error.config);
    //         //return axios.request('http://localhost:3001/createNewAccessToken', {withCredentials: true});
    //         //if we send only url and withcredentials then backend refresh token is empty 
    //         // so we are sending an empty object between url and withcredentials

    //         return axios.post('http://localhost:3001/accessTokenTimedOut',{uname:uname},{withCredentials:true}).
    //         then(res => {
    //                 if(res.data.isTokenRefreshed){
    //                     console.log("accessTokenTimedOut returnded with yaaayy \\\\\\\\/////// ", res);
    //                     return axios.request(originalRequest) ;

    //                 }else{
    //                     console.log("accessTokenTimedOut returnded with -(*_*)- \\\\\\\\/////// ", res);
    //                 }   
    //             }
    //         ); 
            
    //       }else{
    //         console.log("axios interceptor inside else of error is 401 refresh token required", error); 
    //       }
    // })

    const deleteImage = (image_relative_url)=>{
        let imageName = image_relative_url.substring(image_relative_url.lastIndexOf('/')+1);
       // console.log("image name is ", imageName);

        axios({
            url:base_url+"/delete-image.php?delete="+ imageName,
            method:"get",
        }).then((res)=>{
            console.log(res.data);
            if(res.data.deletion_status == "success"){
                //show popup
                window.location.reload();
            }
        }).catch((err)=>console.log("bckend error"));
    }
    
    return(
        <div className="AdminGallery-root-container">

            { isAuthorisedAccess 
            
            ?

            <div>
                <div className="AdminGallery-main-content container">

                    {/*  */}
                    <form onSubmit={sendButtonClicked}>
                        <span>
                        <label>1. Choose a category </label>
                                    
                                    <div className="AdminGallery-filter-section-top-and-bottom">     
                                        <div className="select-category-container ">
                                            <div className="AdminGallery-list-of-work-dropdown-card" onClick={() => setStatusDropDownVisibility(prev => !prev)}>{categoryArrayState.length > 0 ? categoryArrayState[currentIndexOfCategoryListState] : null} <img className="ms-2" src={ic_dropdown_work_list} /></div> 
                                        </div>
                                        <ul className="AdminGallery-list-of-status ml-auto" hidden={!statusDropDownVisibility} >
                                            {
                                                categoryArrayState.length > 0 ?
                                                categoryArrayState.map((item, index)=>{
                                                        return(<li key={index} id={index} onClick={() =>handleStatusFilterClick(index) }>{item}</li>)
                                                    })
                                                : null
                                            }
                                        </ul>  
                                    </div>
                        {/*  */}
                        </span>

                        <span className="mt-4">
                            <label className="pe-4">2. Choose image  </label>
                            
                            <input type="file" id="customFile" onChange={onFileSelected} ref={firstFileRef} />
                        </span>
                        <div>
                            <button type="submit" className="CustomerCreateTickets-create-button mt-4" onSubmit={sendButtonClicked}>Upload Image</button>
                        
                        </div>
                    </form>
                </div>

                <hr></hr>
                <div className="AdminGallery-previous-images container">

                    <p>{loadingItemsTextState}</p>
                    <ul>
                        {arrayOfGalleryImages.length > 0 
                            ? 
                            arrayOfGalleryImages.map((item)=>{
                                return(
                                    <li  key={item.id}>
                                        <div>
                                            <img src={base_url + item.image_url} />
                                        </div>
                                        <div>
                                            <button onClick={()=>deleteImage(item.image_url)}>Delete</button>
                                        </div>
                                    </li>
                                )
                                })
                            : 
                            null  
                        }
                    </ul>
                </div>

                    {/* success popup starts */}
                    {showSuccessPopup  && (<div className="AdminGallery-enquiry-entry-successPopup-outer">
                        <div className="AdminGallery-enquiry-entry-successPopup-inner">
                            <div><img src={ic_check_mark}/><span>Success</span></div> 
                            <div><p>Image uploaded Successfully.</p></div>   
                            <button onClick={() => setShowSuccessPopup(prev => !prev)}>OK</button>          
                        </div>
                        </div>)
                        }
                
                    {/* success popup starts */}

            </div>
            :

            <div className="AdminGallery-unauthorized-access-container container">
                <p>Unauthorized Access</p>
            </div>

        }

        </div>
    )

}

export default AdminGallery;