import React from "react";
import ic_about_us_bg from "../img/ic_about_us_bg.png";
import "../css/AboutUs.css";
import ic_person_work_process from "../img/ic_person_work_process.png";
import ic_design_one_work_process from "../img/ic_design_one_work_process.png";
import ic_design_two_work_process from "../img/ic_design_two_work_process.png";
import ic_design_three_work_process from "../img/ic_design_three_work_process.png";
import ic_collage_1 from "../img/ic_collage_1.png";
import ic_collage_2 from "../img/ic_collage_2.png";
import ic_collage_3 from "../img/ic_collage_3.png";
import ic_page_header_name_bg from "../img/ic_page_header_name_bg.png";
import ic_first_line_work_process from "../img/ic_first_line_work_process.png";
import ic_second_line_work_process from "../img/ic_second_line_work_process.png";
import Footer from "./Footer";


function AboutUs(){
    return(
        <div className="AboutUs-root-container">

            <div className="AboutUs-top-container">
                <div className="AboutUs-image-container">
                    <img src={ic_about_us_bg} />
                </div>
                <div className="AboutUs-statistics-black-overlay">
                </div>
                <div className="AboutUs-text-container">
                    <div className="AboutUs-text-container-details container">
                        <div className="AboutUs-name-panel-container mt-4">
                            <img src={ic_page_header_name_bg}/>
                            <h3>About Us</h3>
                        </div>
                        <p className="mt-4">We would not be here as  what  we are today without the continuous support of our customers and would like to thank you for being part with us during the past 15years."

leisure art marine services llc was founded in 2008 in the Emirate of Dubai in the United Arab Emirates as a supplier and dealers  of anodes,  spare parts of yachts , cleaning &amp; safety materials, OCEAN LED under water light and upholstery and other service jobs .

Throughout the years the company expanded into the field of specialized trading and service in marine. Many of the world’s most reputable brands found their home with leisure artmarine services llc.

Currently the company distributes an extensive array of electrical and mechanical marine parts and equipment from over 100 international marine manufacturers from our locations in the United Arab Emirates supplying throughout the Middle East and providing all kind of service jobs like carpentry, upholstery, mechanical, electrical, navigation, fiberglass, carbon fiber, A/C, hydraulic and painting  , and has a dedicated service center to provide installation and troubleshooting support, technical advice and commissioning services to our customers throughout the region.

We look forward to continued success in the future with cater our customers in all manner</p>
                    </div> 
                </div>
            </div>

            <div className="AboutUs-our-work-process-container">

                <div className="AboutUs-our-work-process-details"> 
                    <div className="container">
                        <h4>Our work process</h4>

                        <div className="AboutUs-work-process-first-panel">
                            <div className="d-flex">
                                <div className="AboutUs-work-flexible-arrow-container-1">
                                    <p>We gather the requirements <br/> and suggestions from <br/> our clients.</p>
                                    <img src={ic_first_line_work_process}/>
                                </div>
                                <img src={ic_person_work_process} className="AboutUs-our-work-process-round-image ms-4"/>
                            </div>
                        </div>
                        <div className="AboutUs-work-process-second-panel">
                            <div className="d-flex">
                                <img src={ic_design_one_work_process} className="AboutUs-our-work-process-round-image me-4"/>
                                <div className="AboutUs-work-flexible-arrow-container-2" >
                                    <p>Creativity and digital mastery <br/> of our team members produces <br/> a perfect design.</p>
                                    <img src={ic_second_line_work_process}/>
                                </div>
                                <img src={ic_design_two_work_process} className="AboutUs-our-work-process-round-image ms-4"/>
                            </div>
                        </div>
                        <div className="AboutUs-work-process-third-panel">
                            <div>
                                <p>Work is completed with utmost <br/> precision and punctuality.</p>
                                <img src={ic_design_three_work_process} className="AboutUs-our-work-process-round-image"/>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
           
            <div className="AboutUs-image-gallery-container">
               <div className="container">
                    <div className="AboutUs-first-container">
                        <img src={ic_collage_1}/>
                        <img src={ic_collage_2}/>
                    </div>
                    <div className="AboutUs-third-container">
                        <img src={ic_collage_3}/>
                    </div>
                    <p className="m-0">premium collections for your perfect ride.</p> 
               </div>

           </div> 


           <Footer/>
        </div>
    )
}

export default AboutUs;