import React, { useEffect, useState } from "react";
import ic_about_us_bg from "../img/ic_about_us_bg.png";
import ic_page_header_name_bg from "../img/ic_page_header_name_bg.png";
import Footer from "./Footer";
import "../css/Gallery.css";
import ic_dropdown_work_list from "../img/ic_dropdown_work_list.png"; 
import axios from "axios";

function Gallery(){

    
    const [loadingItemsTextState, setLoadingItemsTextState] = useState("");
    
    //const base_url = "http://localhost/leisureartmarinephpbackend";
    const base_url = "https://artmarineuae.com/leisureartmarinephpbackend";

    const [arrayOfGalleryImages, setArrayOfGalleryImages] = useState([]);
    const [copyListOfGalleryImages, setCopyListOfGalleryImages] = useState([]);
    const [currentIndexOfCategoryListState, setCurrentIndexOfCategoryListState] = useState(0); 

    const [statusDropDownVisibility, setStatusDropDownVisibility] = useState(false);

    const [categoryArrayState, setCategoryArrayState] = useState(["All","Services","Marine Products Division"]);
    
    const [showImagePopup, setShowImagePopup]= useState(false);
    
    const [positionOfImage, setPositionOfImage]= useState(0);

    const handleImageClick =(pOfImage)=>{
        setPositionOfImage(pOfImage);
       
        setShowImagePopup(true);
    }

    
    const closePopup = ()=>{
        setShowImagePopup(false);
    }

    const handleStatusFilterClick =(index)=>{
        setCurrentIndexOfCategoryListState(index);
        setStatusDropDownVisibility(prev => !prev);
    }

    const getPreviousImagesFromServer = ()=>{

        setLoadingItemsTextState("Loading Images");

        axios.get(base_url + '/get-previous-images.php').
        then((res)=>{

            console.log("front end success ", res);

            if(res.data.gallery_list_array){
           
            setArrayOfGalleryImages(res.data.gallery_list_array);
            setCopyListOfGalleryImages(res.data.gallery_list_array);

                if(res.data.gallery_list_array.length == 0){
                    setLoadingItemsTextState("No images to show");
                }else{
                    setLoadingItemsTextState("");
                }
            
            }else{
                console.log("Could not get images");
                setLoadingItemsTextState("Unable to show images");
            }
           
        }).catch(err => setLoadingItemsTextState("Unable to show images"));
    }

    useEffect(()=>{


        const copiedArray = [...copyListOfGalleryImages];
        console.log("copy of array ", copiedArray);

        if(categoryArrayState[currentIndexOfCategoryListState] == "All"){
            setArrayOfGalleryImages(copiedArray);
        }else{   
            setArrayOfGalleryImages(copiedArray.filter((item)=>{
                    return item.category == categoryArrayState[currentIndexOfCategoryListState];
                }));         
        }

    }, [currentIndexOfCategoryListState])

    useEffect(()=>{
        getPreviousImagesFromServer();
    }, [])
    
    return(
        <div className="Gallery-root-container">
            <div className="Gallery-top-container">
                <div className="Gallery-image-container">
                    <img src={ic_about_us_bg} />
                </div>
                <div className="Gallery-statistics-black-overlay">
                </div>
                <div className="Gallery-text-container">

                    <div className="Gallery-text-container-details ">
                        <div className="Gallery-name-panel-container mt-4 container">
                            <img src={ic_page_header_name_bg}/>
                            <h3>Gallery</h3>
                        </div>
                        <div className="Gallery-list-container">
                            <div className="Gallery-list-container-inner-content container">

                            <label>Choose a category </label>
                            
                            <div className="Gallery-filter-section-top-and-bottom">     
                                <div className="select-category-container ">
                                    <div className="Gallery-list-of-work-dropdown-card" onClick={() => setStatusDropDownVisibility(prev => !prev)}>{categoryArrayState.length > 0 ? categoryArrayState[currentIndexOfCategoryListState] : null} <img className="ms-2" src={ic_dropdown_work_list} /></div> 
                                </div>
                                <ul className="Gallery-list-of-status ml-auto" hidden={!statusDropDownVisibility} >
                                    {
                                        categoryArrayState.length > 0 ?
                                        categoryArrayState.map((item, index)=>{
                                                return(<li key={index} id={index} onClick={() =>handleStatusFilterClick(index) }>{item}</li>)
                                            })
                                        : null
                                    }
                                </ul>  
                            </div>

                            <div className="Gallery-image-list-wrapper mt-4">
                                  {
                                    arrayOfGalleryImages.length > 0 
                                    ?
                                       arrayOfGalleryImages.map((item,index) =>{
                                        return(
                                            <img key={item.id} className="Gallery-image-of-gallery" src={base_url + item.image_url} onClick={() => handleImageClick(index)}/>
                                        )
                                        })  
                                    : 
                                     null
                                }
                            </div>
                            </div>
                        </div>
                    </div>  
                    <Footer/>
                </div>
            </div>

            {
           showImagePopup && <div className="Gallery-outer-shadow-container">

            <span onClick={closePopup}>CLOSE </span>   
            <img src={base_url +  arrayOfGalleryImages[positionOfImage].image_url}/>

            </div> }

        </div>
    )
}

export default Gallery;