import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
import Gallery from "./components/Gallery";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AdminLogin from "./components/AdminLogin";
import AdminHome from "./components/AdminHome";
import AdminGallery from "./components/AdminGallery";

function App() {
  
  return(
    
  <Router>
    <Header/>
    <Routes>
      <Route exact path="/" element={<Home/>} />
      <Route exact path="/aboutus" element={<AboutUs/>} />
      <Route exact path="/services" element={<Services/>} />
      <Route exact path="/gallery" element={<Gallery/>} />
      <Route exact path="/contact" element={<Contact/>} />

      <Route exact path="/admin_login" element={<AdminLogin/>} />
      <Route exact path="/admin_dashboard" element={<AdminHome/>} />
      <Route exact path="/admin_dashboard/gallery" element={<AdminGallery/>} />

    </Routes>
    
  </Router>
  
  )

}

export default App;
